import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const BankingFinancialServices = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="BankingFinancialServices"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">TEQT BPS is driving Banking Business Process Excellence through a digital-first approach, deep domain expertise, led by experienced consultative practitioners to improve customer experience and lower cost of operations.</p>
                        <p className="mt-3">We support over 600 banking processes across Retail & Commercial Banking, Wealth Management and Capital Markets/ Investment Banking. We have over 20 years of experience working on Transformation solutions for Banks & Financial Services institutions, including 2 Fortune 100 Global Banks and 2 of the Top 25 Banks in the U.S.</p>
                        {/* <p className="mt-2">Users with malicious intent are well equipped with different ways and tools to exploit the environment and create security breach situations.</p>
                        <p className="mt-2">Workplace security covers aspects of various methods, technologies, and processes to help protect the confidentiality, integrity, and availability of computer systems, networks, and data, against cyber-attacks or unauthorized access.</p> */}
                        {/* <p className="mt-2">TEQT SAFE offering for workplace security helps in delivering optimized security with right enablement.</p>
                        <p className="mt-2">The SAFE platform entails protecting end-user devices from unauthorized access, protecting the endpoints from malicious attacks, providing faster response to security incidents, and creating an environment of trust.</p>
                        <p className="mt-2">SAFE framework and platform is built on the “prevention first” approach with multilayered security, faster recovery, and unified compliance and management to deliver a zero-trust architecture to our customers.</p> */}



                        <h6>We help organizations with:</h6>
                        <h6>Business Transformation</h6>
                        <ul className="circle-list">
                            <li className="mt-2">40% faster turn time to market
                                35% cost saving</li>
                        </ul>

                        <h6>Enhanced customer satisfaction</h6>
                        <ul className="circle-list">
                            <li className="mt-2">4.5/5 CSAT score for a Top 20 U.S Bank</li>
                        </ul>

                        <h6>Operational Excellence</h6>
                        <ul className="circle-list">
                            <li className="mt-2">20% turn time improvement
                                99.5% accuracy in operations</li>
                        </ul>

                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query BankingFinancialServicesPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

BankingFinancialServices.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default BankingFinancialServices;
